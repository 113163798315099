<template>
  <v-form ref="form" v-model="valid">
    <v-container
      style="justify-content: center; display: flex; flex-direction: column"
    >
      <div class="form-container">
        <v-col style="max-width: 450px">
          <h1 class="text-center">Tus datos</h1>
          <v-col>
            <v-text-field
              v-model="userData.name"
              :rules="nameRules"
              label="Nombre"
              required
              dense
              ><v-icon slot="prepend"> mdi-account </v-icon></v-text-field
            >
          </v-col>

          <v-col>
            <v-text-field
              v-model="userData.surname"
              :rules="surnameRules"
              label="Apellido"
              required
              dense
              ><v-icon slot="prepend"> mdi-account-check </v-icon></v-text-field
            >
          </v-col>

          <v-col>
            <v-text-field
              v-model.lazy="newRut"
              :rules="rutRules"
              label="RUT"
              required
              dense
              hide-details="auto"
              @input="changeRut"
              ><v-icon slot="prepend"> mdi-key </v-icon></v-text-field
            >
          </v-col>

          <v-col>
            <v-text-field
              v-model="userData.serialNumber"
              :rules="serialNumberRules"
              label="N de serie"
              required
              dense
              ><v-icon slot="prepend"> mdi-numeric </v-icon></v-text-field
            >
          </v-col>

          <v-col>
            <v-select
              :items="regions"
              v-model="userData.region"
              :rules="regionRules"
              :loading="loadingRegions"
              v-on:change="getCommunes()"
              item-text="name"
              label="Region"
              required
              dense
              return-object
              ><v-icon slot="prepend"> mdi-map-marker </v-icon></v-select
            >
          </v-col>

          <v-col>
            <v-select
              :items="communes"
              v-model="userData.commune"
              :rules="communeRules"
              :loading="loadingCommunes"
              :disabled="loadingCommunes || userData.region === null"
              item-text="name"
              label="Comuna"
              required
              dense
              return-object
              ><v-icon slot="prepend"> mdi-select-place </v-icon></v-select
            >
          </v-col>

          <v-col>
            <v-text-field
              v-model="userData.address"
              :rules="addressRules"
              label="Dirección"
              required
              dense
              ><v-icon slot="prepend">
                mdi-map-marker-outline
              </v-icon></v-text-field
            >
          </v-col>

          <v-col>
            <v-text-field
              v-model="userData.email"
              :rules="emailRules"
              label="E-mail"
              required
              dense
              ><v-icon slot="prepend"> mdi-at </v-icon></v-text-field
            >
          </v-col>

          <v-col>
            <v-text-field
              v-model="userData.phoneNumber"
              :rules="phoneRules"
              :counter="9"
              prefix="+56"
              label="Telefono"
              required
              dense
              ><v-icon slot="prepend"> mdi-phone </v-icon></v-text-field
            >
          </v-col>
        </v-col>
      </div>
      <v-row style="justify-content: center; margin-top: 1rem">
        <v-btn color="primary" @click="validate()"> Confirmar y pagar </v-btn>
        <v-btn text @click="back()"> Atras </v-btn>
      </v-row>
    </v-container>
  </v-form>
</template>

<script>
import RutUtils from "../utils/rut.utils";
import CountryService from "../services/country.service";

export default {
  name: "PersonForm",
  props: ["userData"],
  data: () => ({
    valid: false,
    nameRules: [(v) => !!v || "El nombre es requerido"],
    surnameRules: [(v) => !!v || "El apellido es requerido"],
    rutRules: [
      (v) => !!v || "El RUT es requerido",
      (v) => RutUtils.validateRut(v) || "Rut no valido",
    ],
    serialNumberRules: [
      (v) => !!v || "El numero de serie es requerido",
      (v) => (!!v && v.length > 8) || "Ingrese número de serie correctamente",
    ],
    regionRules: [
      (v) => !!v || "Ingresa la region en la que vives con tu mascota",
    ],
    communeRules: [(v) => !!v || "Necesitamos saber en que comuna vives"],
    addressRules: [(v) => !!v || "Debe ingresar su dirección"],
    emailRules: [
      (v) => !!v || "El correo electrónico es equerido",
      (v) => /.+@.+/.test(v) || "Ingrese un correo válido",
    ],
    phoneRules: [
      (v) => !!v || "El número telefónico es requerido",
      (v) => /^[0-9]*$/.test(v) || "Ingrese solo números",
      (v) => (!!v && v.length == 9) || "Ingrese un número válido",
    ],
    newRut: "",
    regions: [],
    communes: [],
    loadingRegions: false,
    loadingCommunes: false,
  }),
  mounted() {
    this.getRegions();
  },
  methods: {
    back() {
      this.$emit("back");
    },
    validate() {
      if (this.$refs.form.validate()) {
        this.$emit("next-step");
      }
    },
    changeRut(rut) {
      this.userData.rut = RutUtils.cleanRut(rut);
      this.newRut = RutUtils.formatRut(rut);
    },
    async getRegions() {
      this.loadingRegions = true;
      this.regions = await CountryService.getRegions();
      this.loadingRegions = false;
    },
    async getCommunes() {
      if (this.userData.region) {
        this.loadingCommunes = true;
        this.userData.commune = null;
        this.communes = await CountryService.getCommunes(
          this.userData.region.name
        );
        this.loadingCommunes = false;
      }
    },
  }
};

</script>
<style lang="scss">
.form-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
}
.species-container {
  display: block;
  position: relative;
  text-align: center;
  // margin-bottom: 2rem;
  cursor: pointer;

  .species-selector {
    display: inline-block;
    position: relative;
    margin-bottom: 0.5rem;
    margin-right: 0.5rem;
    // padding: 0.5rem 1rem;
    transition: background-color 0.2s ease-in-out, box-shadow 0.2s ease-in-out;
    // border: 1px solid #f5f5f5;
    cursor: pointer;

    .radio-input {
      padding: 0.5rem;
      display: flex;
      flex-direction: column;
      cursor: pointer;
    }

    &:hover {
      background-color: #f5f5f5;
    }

    &.active {
      background-color: #f5f5f5;
      box-shadow: 4px 4px 4px #c6c6c6;
    }
    input[type="radio"] {
      position: absolute;
      left: 0;
      opacity: 0;
      outline: none;
      z-index: -1;
    }

    .species-icon {
      height: 100px;
      cursor: pointer;
    }
  }
}

.v-col {
  text-align: left;
}

.v-input--radio-group--column .v-input--radio-group__input {
  flex-direction: row !important;
}

@media (max-width: 800px) {
  .form-container {
    flex-direction: column;
    align-items: center;
  }
}
</style>