import axios from "axios";
const { VUE_APP_SHIELD_URL } = process.env;
const { VUE_APP_SPONSOR } = process.env;

export default class ShieldService {
  static async getPlans(plans) {
    try {
      var pack = "MACC-ITP-IC";

      if (plans.length != 0) {
        plans.forEach((element) => {
          while (element.includes(" ")) {
            element = element.replace(" ", "");
          }
          pack = pack + "-" + element;
        });
      }

      // console.log("pack: ", pack);

      const shieldRes = await axios.get(
        `${VUE_APP_SHIELD_URL}/package?sponsor=${VUE_APP_SPONSOR}&pack=${pack}`
      );
      return shieldRes.data.status === "ok" ? shieldRes.data.data : [];
    } catch (error) {
      return {
        status: "error",
        message: "Ocurrio un error al obtener los planes",
      };
    }
  }

  static async createInsurance(person, plan) {
    person.region = person.region.id;
    person.commune = person.commune.id;
    const jsonData = { person, planId: plan._id };
    const shieldRes = await axios.post(
      `${VUE_APP_SHIELD_URL}/package`,
      jsonData
    );
    if (shieldRes.data.status === "ok") return shieldRes.data.data.link;
    else throw shieldRes.data;
  }
}
