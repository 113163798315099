<template>
  <v-row>
      <v-col
        cols="4"
        sm="6"
      >
        <v-hover
          v-slot="{ hover }"
          open-delay="200"
        >
          <v-card
            :elevation="hover ? 16 : 2"
            :class="{ 'on-hover': hover }"
            class="mx-auto"
            height="800"
            max-width="500"
          >
            <v-card-text class="font-weight-medium mt-50 text-center text-subtitle-1">
              Opciones
            </v-card-text>
            <div class="form-container">

              <v-col style="max-width: 350px" >
                                
                <v-col>
                  <v-switch
                    color="primary"
                    value
                    input-value="true"
                    disabled
                    :label="`MACC`"
                  ></v-switch>
                </v-col>

                <v-col>
                  <v-switch
                    color="primary"
                    value
                    input-value="true"
                    disabled
                    :label="`ITP`"
                  ></v-switch>
                </v-col>

                <v-col>
                  <v-switch
                    color="primary"
                    value
                    input-value="true"
                    disabled
                    :label="`IC`"
                  ></v-switch>
                </v-col>

                <v-col>
                  <v-switch 
                    @click="addItem(0, `ITP Temp Acc`)"
                    v-model="stwichers.item0"
                    color="primary"
                    :label="`ITP Temp Acc`"
                  ></v-switch>
                
                </v-col>
                
                <v-col>
                  <v-switch 
                    @click="addItem(1, `Desm Acc`)"
                    v-model="stwichers.item1"
                    color="primary"
                    :label="`Desm Acc`"
                  ></v-switch>
                
                </v-col>

                <v-col>
                  <v-switch 
                    @click="addItem(2, `GMxACC`)"
                    v-model="stwichers.item2"
                    color="primary"
                    :label="`GMxACC`"
                  ></v-switch>
                
                </v-col>

                <v-col>
                  <v-switch 
                    @click="addItem(3, `RGFuneACC`)"
                    v-model="stwichers.item3"
                    color="primary"
                    :label="`RGFuneACC`"
                  ></v-switch>
                
                </v-col>

                <v-col>
                  <v-switch 
                    @click="addItem(4, `RC`)"
                    v-model="stwichers.item4"
                    color="primary"
                    :label="`RC`"
                  ></v-switch>
             
                </v-col>

              </v-col>

            </div>
          </v-card>
        </v-hover>
      </v-col>
      <v-col
        cols="4"
        sm="6"
      >
      <v-hover
          v-slot="{ hover }"
          open-delay="200"
        >
        <v-card
          :elevation="hover ? 16 : 2"
          :class="{ 'on-hover': hover }"
          class="mx-auto"
          max-width="500"
          height="800"
        >
            <v-dialog
              v-model="dialog"
              width="500"
            >
              <template v-slot:activator="{ on, attrs }">
                <div class="text-center" >
                    
                    <v-row>
                      <v-col cols="8">
                        <v-subheader>Precio actual</v-subheader>
                      </v-col>
                      <v-col cols="3">
                        <v-text-field
                          text-align="center"
                          label="Monto"
                          :value="price"
                          prefix="$"
                          disabled
                        > 
                          <v-icon 
                          slot="prepend" 
                          big 
                          @click="calculedPrice()" 
                          >  
                          mdi-help-circle  
                          </v-icon> 
                        </v-text-field>
                      </v-col>
                    </v-row>

                    <v-card-text class="font-weight-medium mt-50 text-center text-subtitle-1">
                      Coberturas
                    </v-card-text>

                    <br>
                    
                    <v-for v-for="(item, i) in planItemsSelected"
                      :key="i"
                      rounded
                      dark
                      v-bind="attrs"
                      v-on="on"
                      @click="Info1(i, item)"
                    >
                     <v-btn
                      color="secundary"
                     >
                      <li>
                        {{item}}
                      </li>
                     </v-btn>

                     <br>
                     <br>
                     
                    </v-for>
                    
                    <v-for v-for="(item, i) in planItemsOptional"
                      :key="i"
                      rounded
                      dark
                      v-bind="attrs"
                      v-on="on"
                      @click="Info2(item)"
                    >
                     <v-btn
                      color="secundary"
                     >
                      <li>
                        {{item}}
                      </li>
                     </v-btn>
                     <br>
                     <br>
                    </v-for>

                    <v-text-field> ------------------------------------------------------------- </v-text-field>

                    <v-row style="justify-content: center; margin-top: 1rem">
                      <v-btn color="primary" @click="validate()"> Siguiente </v-btn>
                      <v-btn text @click="back()"> Atras </v-btn>
                    </v-row>

                  </div>
              </template>

              <v-card>
                <v-card-title class="text-h5 grey lighten-2">
                  {{infoExpose[0]}}
                </v-card-title>

                <v-card-text>
                  {{infoExpose[1]}}
                </v-card-text>

                <v-divider></v-divider>

                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn
                    color="primary"
                    text
                    @click="dialog = false"
                  >
                    De acuerdo!
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
        </v-card>
      </v-hover>
      </v-col>
  </v-row>
</template>

<script>

export default {
  name: "DetailForm",
  props: ["planItemsSelected", "planItemsOptional", "stwichers", "pricesOptionals", "pricesSelected"],
  data: () => ({
    dialog: false,
    price: 0,
    infoSelected: ["Info plan 1", "Info plan 2", "Info plan 3"],
    infoOptional: [["ITP Temp Acc","Info plan 1"], ["Desm Acc", "Info plan 2"], ["GMxACC", "Info plan 3"], ["RGFuneACC", "Info plan 4"], ["RC", "Info plan 5"]],
    infoExpose: [],
    loadingPlans: true,
  }),
  mounted() {
    this.startprice()
  },
  methods: {
    validate() {
      this.$emit("next-step");
    },
    back() {
      this.$emit("back");
    },
    startprice(){
      var priceOp = 0
      this.pricesSelected.forEach(element => {  
        priceOp = priceOp + element[0]       
      })

      this.price = priceOp
    },    
    Info1(i, item){

      this.infoExpose = [this.infoSelected[i], item]
    },
    Info2(item){
      
      var i;
      var pos = 0
      this.infoOptional.forEach(element => {
        if(element[0] == item){
          i = pos
        }
        pos ++
      });

      this.infoExpose = [this.infoOptional[i][0], this.infoOptional[i][1]]
    },
    addItem(boton, planName) {
      
      var option;
      if(boton == 0){
        option = this.stwichers.item0
      }else if(boton == 1){
        option = this.stwichers.item1
      }else if(boton == 2){
        option = this.stwichers.item2
      }else if(boton == 3){
        option = this.stwichers.item3
      }else if(boton == 4){
        option = this.stwichers.item4
      }

      if(option == false){

        var cont = 0
        this.planItemsOptional.forEach(element => {

          if(element == planName){

            this.planItemsOptional.splice(cont, 1)

          }

          cont = cont + 1
        });

      } else{
        this.planItemsOptional.push(planName)
      }

      this.calculedPrice()

    },
    calculedPrice() {

      var priceEnd = 0

      var priceOp = 0
      this.pricesSelected.forEach(element => {   
        priceOp = priceOp + element[0]       
      })

      var priceSt = 0
      this.planItemsOptional.forEach(name => {

        this.pricesOptionals.forEach(prices => {  
          
          if(name == prices[1]){
            priceSt = priceSt + prices[0]       
          }
        });

      });

      priceEnd = priceOp + priceSt

      this.price = priceEnd

    },
  },
};
</script>
<style lang="scss">
.switch-center {
  display: flex;
  justify-content: center;
}
.form-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
}
.species-container {
  display: block;
  position: relative;
  text-align: center;
  // margin-bottom: 2rem;
  cursor: pointer;

  .species-selector {
    display: inline-block;
    position: relative;
    margin-bottom: 0.5rem;
    margin-right: 0.5rem;
    // padding: 0.5rem 1rem;
    transition: background-color 0.2s ease-in-out, box-shadow 0.2s ease-in-out;
    // border: 1px solid #f5f5f5;
    cursor: pointer;

    .radio-input {
      padding: 0.5rem;
      display: flex;
      flex-direction: column;
      cursor: pointer;
    }

    &:hover {
      background-color: #f5f5f5;
    }

    &.active {
      background-color: #f5f5f5;
      box-shadow: 4px 4px 4px #c6c6c6;
    }
    input[type="radio"] {
      position: absolute;
      left: 0;
      opacity: 0;
      outline: none;
      z-index: -1;
    }

    .species-icon {
      height: 100px;
      cursor: pointer;
    }
  }
}

.v-col {
  text-align: left;
}

.v-input--radio-group--column .v-input--radio-group__input {
  flex-direction: row !important;
}

@media (max-width: 800px) {
  .form-container {
    flex-direction: column;
    align-items: center;
  }
  .switch-center {
  display: flex;
  justify-content: center;
  }
}
</style>