<template>

  <v-container style="justify-content: center; display: flex">
  
    <v-col>
      <div class="plan-container">
        <Plan v-for="plan in plans" :key="plan._id" :planData="plan" @next-step="validate" />
      </div>
      <v-row style="justify-content: center; margin-top: 1rem">
        <v-btn text @click="back()"> Atras </v-btn>
      </v-row>
    </v-col>
  </v-container>
</template>

<script>
import Plan from "@/components/Plan.vue";

export default {
  name: "PlanForm",
  props: ["planData", "plans"],
  components: { Plan },
  data: () => ({
    loadingPlans: true,
  }),
  mounted() {
  },
  methods: {
    back() {
      this.$emit("back");
    },
    validate(plan) {
      this.$emit("next-step", 2, 2, plan);
    },
  },
};
</script>


<style lang="scss" scoped>
.plan-container {
  justify-content: center;
  display: flex;
  flex-wrap: wrap;
}
</style>